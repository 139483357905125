
import blockTitleRow from '@components/blocks/block-title-row/index.vue';

export default {
  components: {
    blockTitleRow,
  },
  data() {
    return {
      reports: [],
      loading: false,
      headers: [
        {
          text: 'Student',
          align: 'left',
          value: 'student_name',
          sortable: true,
        },
        {
          text: 'Exam',
          align: 'left',
          value: 'exam_name',
          sortable: true,
        },
        {
          text: 'VR',
          align: 'left',
          value: 'verbal_reasoning_score',
          sortable: true,
        },
        {
          text: 'DM',
          align: 'left',
          value: 'decision_making_score',
          sortable: true,
        },
        {
          text: 'QR',
          align: 'left',
          value: 'quantitative_reasoning_score',
          sortable: true,
        },
        {
          text: 'AR',
          align: 'left',
          value: 'abstract_reasoning_score',
          sortable: true,
        },
        {
          text: 'SJ',
          align: 'left',
          value: 'situational_judgement_score',
          sortable: true,
        },
        {
          text: 'TOTAL',
          align: 'left',
          value: 'score_total',
          sortable: true,
        },
        {
          text: 'DATE',
          align: 'left',
          value: 'date',
          sortable: true,
        },
      ],
    };
  },
  async fetch() {
    this.loading = true;
    const { data } = await this.$apiFactories.student.getReports();
    this.reports = data;
    this.loading = false;
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        return a < b;
      });
      return items;
    },
    handleRowClick(item) {
      return this.$router.push(`exam/${item.exam_hash}`);
    },
  },
};
